// @flow
import {useState} from 'react';

export const useConfirmClose = (
  onClose: () => void,
): ({
  isDirty: boolean,
  setIsDirty: (isDirty: boolean) => void,
  handleConfirmClose: () => void,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const handleConfirmClose = () => {
    if (!isDirty) {
      onClose();
      return;
    }

    const isConfirmed = window.confirm(
      'Unsaved changes will be lost. Are you sure you want to continue?',
    );

    if (isConfirmed) {
      onClose();
    }
  };

  return {
    isDirty,
    setIsDirty,
    handleConfirmClose,
  };
};
