// @flow
import React from 'react';
import type {Node, ComponentType} from 'react';
import {observer} from 'mobx-react';
import {Chip, TextField} from '@wellstone-solutions/web';
import type {UICollectionType} from '@wellstone-solutions/common/models/rest';
import {Autocomplete} from '@mui/material';
import {useStores} from 'hooks/useStores';

type PropsType = {
  value: Array<UICollectionType>,
  onChange: (newValue: Array<UICollectionType>) => void,
};

export const CollectionSelect: ComponentType<PropsType> = observer(
  ({value = [], onChange}: PropsType): Node => {
    const {resourceV2Store} = useStores();

    return (
      <Autocomplete
        fullWidth
        multiple
        data-testid="collection-select"
        options={resourceV2Store.collections}
        onChange={(e, newValue) => onChange(newValue)}
        value={value}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Categories" />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => (
            <Chip
              label={option.name}
              variant="filled"
              {...getTagProps({index})}
            />
          ))
        }
      />
    );
  },
);
