// @flow
import type {Node} from 'react';
import {
  Button,
  MenuItem,
  // $FlowFixMe
} from '@wellstone-solutions/web';
import {AnnouncementModel} from '../../models/announcement';
import type {AnnouncementsDataGridType} from '../../types';

type PropsType = {
  onCancel: (string, string) => void,
  row: AnnouncementsDataGridType,
  isMenuItem?: boolean,
};

const getConfirmMessage = (action, name) =>
  `Are you sure you want to ${action} "${name}"?`;

export const CancelActionButton = ({
  onCancel,
  row,
  isMenuItem = false,
}: PropsType): Node => {
  if (AnnouncementModel.isDeleted(row)) {
    return null;
  }

  const actionText = AnnouncementModel.isPublished(row)
    ? 'Unpublish'
    : AnnouncementModel.canHardDelete(row)
    ? 'Permanently Delete'
    : 'Cancel';

  if (isMenuItem) {
    return (
      <MenuItem
        onClick={() =>
          onCancel(
            row.id,
            getConfirmMessage(actionText.toLowerCase(), row.title),
          )
        }>
        {actionText}
      </MenuItem>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={() => {
        onCancel(
          row.id,
          getConfirmMessage(actionText.toLowerCase(), row.title),
        );
      }}
      color="primary"
      sx={{
        flex: 1,
        textTransform: 'none',
      }}>
      {actionText}
    </Button>
  );
};
