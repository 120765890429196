// @flow
import {useMemo} from 'react';
import type {UILocationType} from '@wellstone-solutions/common';
import type {UICollectionType} from '@wellstone-solutions/common/models/rest';

type PropsType = {
  query: string,
  location: UILocationType | null,
  collections: Array<UICollectionType>,
};

const DEFAULT_DISTANCE = 25;

export const useActiveFilters = ({
  query,
  location,
  collections = [],
}: PropsType): {
  query?: string,
  lat?: number,
  lon?: number,
  state?: string,
  distance?: number,
  collections?: string[],
} => {
  const activeFilters = useMemo(() => {
    let activeFilters = {};
    if (query) {
      activeFilters.query = query;
    }

    const {latitude, longitude, state, city} = location || {};
    if (latitude && longitude) {
      activeFilters.lat = latitude;
      activeFilters.lon = longitude;

      if (state && !city) {
        activeFilters.state = state;
      } else {
        activeFilters.distance = DEFAULT_DISTANCE;
      }
    }

    if (collections.length) {
      activeFilters.collections = collections.map(
        (collection) => collection.id,
      );
    }

    return activeFilters;
  }, [query, location, collections]);

  return activeFilters;
};
