// @flow
import type {LabelValueType} from 'types';

export const domains: {
  resources: LabelValueType,
  announcements: LabelValueType,
} = {
  resources: {label: 'Resources', value: 'resources'},
  announcements: {label: 'Announcements', value: 'announcements'},
};

export const root = 'content-management';
export const defaultDomain = domains.resources.value;
