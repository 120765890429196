// @flow
const DEFAULT = 'Unknown';

const getStatus = (status) => {
  if (!status) {
    return DEFAULT;
  }

  if (status === 'deleted') {
    return 'canceled';
  }

  return status;
};

export const getPublishedStatus = (
  status: string,
): {label: string, color: string} => {
  let color = 'primary';

  const safeStatus = getStatus(status);

  switch (safeStatus.toLowerCase()) {
    case 'active':
    case 'published':
      color = 'success';
      break;
    case 'canceled':
      color = 'error';
      break;
    default:
      color = 'info';
      break;
  }

  return {label: safeStatus.toUpperCase(), color};
};
