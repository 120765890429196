// @flow
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import type {AnnouncementsCalendarType} from '../../types';

type PropsType = {
  event: AnnouncementsCalendarType,
  onOpenContextMenu: (MouseEvent, AnnouncementsCalendarType) => void,
  children: Node,
};

export const CalendarEventWrapper = ({
  event,
  onOpenContextMenu,
  children,
}: PropsType): Node => {
  return (
    <Box
      data-testid="calendar-event-wrapper"
      onContextMenu={(e) => {
        e.preventDefault();
        onOpenContextMenu(e, event);
      }}>
      {children}
    </Box>
  );
};
