// @flow
import {useState} from 'react';
import type {Node} from 'react';
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import {useTheme} from '@mui/material/styles';

// $FlowIgnoreMe
import 'react-big-calendar/lib/css/react-big-calendar.css';
import type {AnnouncementsCalendarType} from '../../types';
import {CalendarContextMenu} from './CalendarContextMenu';
import {CalendarEventWrapper} from './CalendarEventWrapper';
import {getPublishedStatus} from 'utils/getPublishedStatus';

const localizer = momentLocalizer(moment);

type PropsType = {
  events: Array<AnnouncementsCalendarType>,
  onSelectEvent: (AnnouncementsCalendarType) => void,
  date: Date,
  onNavigate: (Date) => void,
  onCancel: (id: string, message: string) => void,
};

export const Calendar = ({
  events,
  onSelectEvent,
  date,
  onNavigate,
  onCancel,
}: PropsType): Node => {
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const theme = useTheme();

  const openContextMenu = (
    e: MouseEvent,
    announcement: AnnouncementsCalendarType,
  ) => {
    e.preventDefault();
    setSelectedAnnouncement(announcement);
    setContextMenu({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  const getEventProps = (event: AnnouncementsCalendarType) => {
    const {color} = getPublishedStatus(event.resource.status);
    return {
      style: {
        backgroundColor: theme.palette[color].main,
      },
    };
  };

  return (
    <>
      <CalendarContextMenu
        position={contextMenu}
        selectedAnnouncement={selectedAnnouncement}
        onCancel={onCancel}
        onClose={() => setContextMenu(null)}
        onExited={() => setSelectedAnnouncement(null)}
      />
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onSelectEvent}
        style={{height: '100vh'}}
        date={date}
        onNavigate={onNavigate}
        eventPropGetter={getEventProps}
        components={{
          eventWrapper: (props) => (
            <CalendarEventWrapper
              {...props}
              onOpenContextMenu={openContextMenu}
            />
          ),
        }}
      />
    </>
  );
};
