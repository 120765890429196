// @flow
import type {Node} from 'react';
import {Chip} from '@wellstone-solutions/web';
import {getPublishedStatus} from 'utils/getPublishedStatus';

type PropsType = {
  status: string,
};

export const PublishedStatusChip = ({status}: PropsType): Node => {
  const {label, color} = getPublishedStatus(status);
  return <Chip label={label} color={color} variant="filled" />;
};
