// @flow
import React, {useRef, useState, useEffect} from 'react';
import type {Node} from 'react';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  UIResourceType,
  UIResourceValidationType,
} from '@wellstone-solutions/common/models/rest';
import Autocomplete from '@mui/material/Autocomplete';
import {Hooks} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  RichTextEditor,
  FormRow,
  Stack,
  TextField,
  Typography,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {locationStates} from 'modules/app';
import {schema} from './schema';
import {CollectionSelect} from '../CollectionSelect';

type PropsType = {
  initialValues: UIResourceType | null,
  onSubmit: (
    values: UIResourceType,
  ) => Promise<ApiResponseType<UIResourceType | null>>,
  onCancel: () => void,
  setIsDirty: (isDirty: boolean) => void,
};

export const ResourceForm = ({
  initialValues,
  onSubmit,
  onCancel,
  setIsDirty,
}: PropsType): Node => {
  const {alertStore} = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const descriptionRef = useRef();

  const parseValidation = (
    validation: UIResourceValidationType,
  ): {[fieldName: string]: string} => {
    let errors = {};

    if (!validation.emailValid) {
      errors.email = 'Unable to verify email address';
    }

    if (!validation.phoneNumberValid) {
      errors.phoneNumber = 'Unable to verify phone number';
    }

    if (!validation.websiteValid) {
      errors.website = 'Unable to verify website';
    }

    if (!validation.addressValid) {
      errors.address = 'Unable to verify address';
    }

    return errors;
  };

  const form = Hooks.useForm({
    initialValues,
    initialStatus: {
      apiErrors: initialValues?.validation
        ? parseValidation(initialValues.validation)
        : {},
    },

    schema,
    onSubmit: async (values, {resetForm}) => {
      setIsSubmitting(true);
      const response = await onSubmit(values);

      if (response.isSuccess) {
        const apiErrors = response.data?.validation
          ? parseValidation(response.data.validation)
          : {};

        if (Object.keys(apiErrors).length > 0) {
          alertStore.addWarning(
            'Resource saved, but some fields could not be verified',
          );
        } else {
          alertStore.addSuccess('Successfully saved resource!');
        }

        resetForm({values: response.data, status: {apiErrors}});
      } else {
        alertStore.addError('Failed to save resource');
      }

      setIsSubmitting(false);
    },
  });

  const handleDescriptionChange = () => {
    const content = descriptionRef?.current?.getInstance().getMarkdown() || '';
    form.handleChange({target: {name: 'description', value: content}});
  };

  useEffect(() => {
    setIsDirty(form.dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.dirty]);

  const hasFieldError = (fieldName: string): boolean => {
    return (
      (form.touched[fieldName] && Boolean(form.errors[fieldName])) ||
      (!form.touched[fieldName] && Boolean(form.status.apiErrors[fieldName]))
    );
  };

  const getHelperText = (fieldName: string): string => {
    return (
      (form.touched[fieldName] && form.errors[fieldName]) ||
      (!form.touched[fieldName] && form.status.apiErrors[fieldName])
    );
  };

  return (
    <Box>
      <form onSubmit={form.handleSubmit}>
        <FormRow>
          <TextField
            formField="title"
            label="Title"
            value={form.values.title}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.title && Boolean(form.errors.title)}
            helperText={form.touched.title && form.errors.title}
          />
        </FormRow>

        <FormRow>
          <TextField
            formField="subtitle"
            label="Subtitle"
            value={form.values.subtitle}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.subtitle && Boolean(form.errors.subtitle)}
            helperText={
              (form.touched.subtitle && form.errors.subtitle) ||
              'Words that identify the resource service or sector. (e.g. “Public transportation services” or “Counseling & therapy”)'
            }
          />
        </FormRow>

        <Typography variant="h6" mt={2}>
          Description
        </Typography>

        <FormRow>
          <RichTextEditor
            initialValue={form.values.description}
            previewStyle="vertical"
            height="300px"
            usageStatistic={false}
            hideModeSwitch={false}
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            toolbarItems={[
              ['heading', 'bold', 'italic', 'hr', 'ul', 'ol', 'quote', 'link'],
            ]}
            onChange={handleDescriptionChange}
            // $FlowFixMe
            ref={descriptionRef}
          />
        </FormRow>

        <FormRow>
          <CollectionSelect
            value={form.values.collections}
            onChange={(newValue) => {
              form.handleChange({
                target: {name: 'collections', value: newValue},
              });
            }}
          />
        </FormRow>

        <Typography variant="h6" mt={2}>
          Contact Details
        </Typography>

        <FormRow>
          <TextField
            formField="email"
            label="Email"
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={hasFieldError('email')}
            helperText={getHelperText('email')}
          />
        </FormRow>

        <FormRow>
          <TextField
            formField="phoneNumber"
            label="Phone"
            value={form.values.phoneNumber}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={hasFieldError('phoneNumber')}
            helperText={getHelperText('phoneNumber')}
          />
        </FormRow>

        <FormRow>
          <TextField
            formField="website"
            label="Website"
            value={form.values.website}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={hasFieldError('website')}
            helperText={getHelperText('website')}
          />
        </FormRow>

        <FormRow>
          <TextField
            formField="dateTimeInformation"
            multiline
            minRows={3}
            label="Date & Time Information"
            value={form.values.dateTimeInformation}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.dateTimeInformation &&
              Boolean(form.errors.dateTimeInformation)
            }
            helperText={
              form.touched.dateTimeInformation &&
              form.errors.dateTimeInformation
            }
          />
        </FormRow>

        <Typography variant="h6" mt={2}>
          Location
        </Typography>

        {form.values.location?.address && form.status.apiErrors.address && (
          <Typography variant="body2" color="error">
            {form.status.apiErrors.address}
          </Typography>
        )}

        <FormRow direction="row" spacing={2}>
          <TextField
            fullWidth
            formField="location.address"
            label="Address"
            value={form.values.location?.address}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.location?.address &&
              Boolean(form.errors.location?.address)
            }
            helperText={
              form.touched.location?.address && form.errors.location?.address
            }
          />

          <TextField
            fullWidth
            formField="location.address2"
            label="Address 2"
            value={form.values.location?.address2}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.location?.address2 &&
              Boolean(form.errors.location?.address2)
            }
            helperText={
              form.touched.location?.address2 && form.errors.location?.address2
            }
          />
        </FormRow>

        <FormRow direction="row" spacing={2}>
          <TextField
            fullWidth
            formField="location.city"
            label="City"
            value={form.values.location?.city}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.location?.city && Boolean(form.errors.location?.city)
            }
            helperText={
              form.touched.location?.city && form.errors.location?.city
            }
          />

          <Autocomplete
            fullWidth
            value={locationStates.find(
              (state) => state.value === form.values.location?.state,
            )}
            options={locationStates}
            onChange={(e, newValue) => {
              form.handleChange({
                target: {name: 'location.state', value: newValue?.value || ''},
              });
            }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />

          <TextField
            formField="location.zipcode"
            label="Zip"
            value={form.values.location?.zipcode}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              form.touched.location?.zipcode &&
              Boolean(form.errors.location?.zipcode)
            }
            helperText={
              form.touched.location?.zipcode && form.errors.location?.zipcode
            }
            sx={{width: '325px'}}
          />
        </FormRow>

        <Stack
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          my={4}>
          <Button variant="text" onClick={onCancel} sx={{marginRight: 4}}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            type="submit"
            disabled={!form.dirty || !form.isValid || isSubmitting}>
            Save
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
