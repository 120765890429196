// @flow
import React from 'react';
import type {Node} from 'react';
import type {AnnouncementsCalendarType} from '../../types';
import {Menu, MenuItem} from '@wellstone-solutions/web';
import {CancelActionButton} from './CancelActionButton';
import {AnnouncementModel} from '../../models/announcement';

type PropsType = {
  position: ?{mouseX: number, mouseY: number},
  selectedAnnouncement: AnnouncementsCalendarType | null,
  onClose: () => void,
  onCancel: (id: string, message: string) => void,
  onExited?: () => void,
};

export const CalendarContextMenu = ({
  position,
  selectedAnnouncement,
  onClose,
  onCancel,
  onExited,
}: PropsType): Node => {
  return (
    <Menu
      open={position !== null}
      onClose={onClose}
      TransitionProps={onExited ? {onExited} : {}}
      anchorReference="anchorPosition"
      anchorPosition={
        position ? {top: position.mouseY, left: position.mouseX} : undefined
      }>
      {selectedAnnouncement &&
      !AnnouncementModel.isDeleted(selectedAnnouncement.resource) ? (
        <CancelActionButton
          row={selectedAnnouncement.resource}
          onCancel={(id, message) => {
            onCancel(id, message);
            onClose();
          }}
          isMenuItem={true}
        />
      ) : (
        <MenuItem disabled>No actions available</MenuItem>
      )}
    </Menu>
  );
};
